<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">新建文档</span>
        </div>
    </div>
    <div class="bgf mart20 padt30 padb30 padr30">
        <el-form :model="form" ref="submitForm" label-width="140px" :rules="rules" size="large">
            <el-form-item label="文档标题：" prop="docTitle" >
                <el-input
                    v-model="form.docTitle"
                    placeholder="输入文档的名称、文档描述等"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="文档内容：" prop="content">
                <v-md-editor  @upload-image="handleUploadImage" mode="editable" :disabled-menus="[]" v-model="form.content" width="400px" height="400px"></v-md-editor>
                <!-- <div class="font14" style="width: 100%;box-sizing: border-box; z-index: 6;border: 1px solid #dfe2e8">
                    <Toolbar
                        style="border-bottom: 1px solid #dfe2e8"
                        :editor="editorRef"
                        mode="default"
                    />
                    <Editor
                    style="height: 550px; overflow-y: hidden;"
                    v-model="form.content"
                    :defaultConfig="editorConfig"
                    mode="default"
                    @onCreated="editorRef = $event"
                    />
                </div> -->
            </el-form-item>
            <el-form-item label="个人文档分类：" prop="userCategoryId">
                <el-select-v2
                    v-model="form.userCategoryId"
                    placeholder="选择个人文档分类"
                    class="w300"
                    filterable
                    clearable
                    :loading="vselect.userDocCate.loading"
                    :options="vselect.userDocCate.options"
                />
            </el-form-item>
            <el-form-item prop="isCompany" label="投稿给公司：">
                <el-radio-group v-model="form.isCompany">
                    <el-radio
                    :value="1"
                    >是</el-radio>
                    <el-radio
                    :value="0"
                    >否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="companyCategoryId" v-if="form.isCompany == 1">
                <template #label>
                    <span class="padl30 lh20"></span>
                </template>
                <el-cascader
                    v-model="form.companyCategoryId"
                    size="large"
                    class="w300"
                    :props="companyCategory.props"
                    :options="companyCategory.options"
                    clearable
                    placeholder="请选择公司文档分类"
                />
            </el-form-item>
            <el-form-item prop="isSystem" label="投稿给海量：">
                <el-radio-group v-model="form.isSystem">
                    <el-radio
                        :value="1"
                    >是</el-radio>
                    <el-radio
                        :value="0"
                    >否</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </div>
    <div class="bgf mart40 padt16 padb16 padl70">
        <el-button type='primary' @click="submit(1)">发布文档</el-button>
    </div>
</template>
<script>
import { Back } from '@element-plus/icons-vue'
import '@wangeditor/editor/dist/css/style.css'
import { shallowRef } from 'vue'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    setup() {
        return {
            editorRef: shallowRef(),
        }
    },
    components: {
        Back,
        // Editor,
        // Toolbar
    },
    data() {
        return {
            companyCategory: {
                props: {
                    checkStrictly: false,
                    emitPath: true,
                    lazy: true,
                    lazyLoad: this.companyCategory_lazyLoad
                },
                options: [],
            },
            vselect: {
                userDocCate: {
                    loading: false,
                    options: []
                },
            },
            editorConfig: { placeholder: '请输入内容...' },
            form: {
                isCompany: 0,
                isSystem: 0
            },
            rules: {
                docTitle: [{ required: true, message: '请输入文档标题', trigger: 'blur' }],
                content: [{ validator: (rule, value, callback) => {
                    if (value) {
                        return callback()
                    }
                    return callback(new Error('请输入文档内容！'))
                }, required: true }],
                userCategoryId: [{ required: true, message: '请选择文档分类', trigger: 'change' }],
                companyCategoryId: [{ validator: (rule, value, callback) => {
                    if (Array.isArray(value)) {
                        if (value.length == 2) {
                            return callback()
                        }
                    }
                    return callback(new Error('请选择公司文档分类！'))
                }, trigger: 'change' }],
            },
            fullscreenLoading: false,
            sumbitLoading: false,
        }
    },
    methods: {
        handleUploadImage(event, insertImage, files){
			console.log('图片',files)
			var formData = new FormData();
    		formData.append('multipartFile', files[0]);
    		formData.append('type', 4);
			this.$api.base.ossUploadList(formData).then(res=>{
                console.log('上传图片',res)
				insertImage({
					url:res.path,
					desc: files[0].name,
					// width: '300px',
					height: 'auto',
				});
			})
		},
        async getData(id) {
            try {
                this.fullscreenLoading = true
                const result = await this.$api.know.getDocDetail({ id })
                console.warn(result, 'rjewirjeojrwoi')
                this.form.content = result.docContent??''
                this.form.isCompany = result.isCompany
                this.form.isSystem = result.isSystem
            } catch (e) {
                return console.log(e)
            } finally {
                this.fullscreenLoading = false
            }
        },
        
        async companyCategory_lazyLoad(node, resolve) {
            console.log('nodenodenode',node)
            try {
                if (node.level == 0) {
                    const result = await this.$api.document.userCompanyLists()
                    console.log('公司列表',result)
					return resolve(result.map(item => ({ value: item.id, label: item.name })))
                }else if (node.level == 1) {
                    const { records } = await this.$api.document.companyDocument({ pageNumber: 1, pageSize: 1000, companyId: node.data.value })
                    console.log('二级records',records)
                    return resolve(records.map(item => ({ value: item.id, label: item.categoryTitle, leaf: item.list.length>0?false:true,list:item.list })))
                } else if (node.level == 2) {
                    console.log('三级',node)
                    return resolve(node.data.list.map(item => ({ value: item.id, label: item.categoryTitle, leaf: true })))
                }
                return resolve([])
            } catch (e) {
                return resolve([])
            }
        },
        async submit(draft) {
            try {
                this.sumbitLoading = this.fullscreenLoading = true
                console.log(this.form)
                await this.$refs['submitForm'].validate()
                const _data = {
                    id: this.form.id,
                    docTitle: this.form.docTitle,
                    isCompany: this.form.isCompany,
                    isSystem: this.form.isSystem,
                    userCategoryId: this.form.userCategoryId,
                    draft
                }
                if (_data.isCompany == 1) {
                    _data.companyId = this.form.companyCategoryId?.[0]
                    _data.companyCategoryId = this.form.companyCategoryId[this.form.companyCategoryId.length-1]
                }
                const result = await this.$api.document.insertDoc(_data, {
                    content: this.form.content,
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.$router.replace({
                        name: 'documentMy'
                    })
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.sumbitLoading = this.fullscreenLoading = false
            }
        },
        async vselect_userDocCate() {
            try {
                this.vselect.userDocCate.loading = true
                const { records, total } = await this.$api.know.userDocCategorylist({
                    pageNumber: 1,
                    pageSize: 1000
                })
                this.vselect.userDocCate.options = records.map(item => {
                    return {
                        value: item.id,
                        label: item.categoryTitle
                    }
                })
                this.vselect.userDocCate.loading = false
            } catch (e) {
                this.vselect.userDocCate.loading = false
                this.vselect.userDocCate.options = []
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const state = history.state
            if (state.id) {
                vm.form = {
                    id: state.id,
                    docTitle: state.docTitle,
                    userCategoryId: state.userCategoryId,
                    companyCategoryId: state.companyCategoryId,
                }
                vm.getData(state.id)
            }
        })
    },
    mounted() {
        this.vselect_userDocCate()
    },
}
</script>
<style scoped>
</style>